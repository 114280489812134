/* Fonts */

@font-face {
    font-family: 'Monument';
    src: url('../assets/fonts/MonumentExtended.otf') format('opentype');
}
@font-face {
    font-family: 'NeueHaas-Medium';
    src: url('../assets/fonts/NeueHaasDisplayMedium.ttf') format('truetype');
}
@font-face {
    font-family: 'NeueHaas-Roman';
    src: url('../assets/fonts/NeueHaasDisplayRoman.ttf') format('truetype');
}