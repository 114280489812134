/*
* ------------------------------------------
*  Responsive Grid Media Queries - 1280, 1024, 768, 480
*   1280-1024   - desktop (default grid)
*   1024-768    - tablet landscape
*   768-480     - tablet 
*   480-less    - phone landscape & smaller
* --------------------------------------------
*/

@media all and (min-width: 1024px) and (max-width: 1280px) {
}

/* styles for ALL mobile sizes */
@media all and (max-width: 1024px) {
    .user-details {
        display: none;
    }
    /* .mobile-mask {
        display: flex;
    }
    body {
        height: 100vh;
        overflow: hidden;
    } */
    .open-nav-menu,
    .close-nav-menu {
        display: inline-block;
    }
    .navlink-wrapper {
        display: flex;
        gap: 0px;
        flex-direction: column;
        justify-content: flex-start;
        background-color: #fafbff;
        padding-bottom: 15px;
        right: 0;
        width: 170px;
        height: auto;
        position: absolute;
        transform: translateX(100%);
    }
    .navlink-wrapper.open {
        transform: translateX(0);
    }
    .dropdown-content {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        position: relative;
        box-shadow: none;
    }
    .dropdown-content .navlink {
        font-size: 14px;
        padding: 6px 16px 6px 24px;
    }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
    /* body, html {
        color: red;
    } */
    .discounts-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 15px;
    }
    .discount-wrapper {
        width: 100%;
        margin-bottom: 10px;
    }
    .merch-wrapper {
        gap: 30px;
    }
    .footer-wrapper {
        padding: 40px 0;
    }
    .bg-image-wrapper {
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
        height: 200px;
    }
    .hero > .bg-image-wrapper {
        height: 400px;
    }
    .verify-wrapper {
        grid-template-columns: 1fr 1fr;
    }
    .bigtext-wrapper {
        padding: 35px 0 20px 0;
    }
    .bigtext-wrapper > h1 {
        font-size: 50px;
    }
    .skis-image-vertical {
        display: none;
    }
    .skis-image-horizontal {
        display: block;
        height: 800px;
        top: -590px;
    }
    .carousel-wrapper {
        height: 70px;
    }
    .sponsor-wrapper {
        padding: 0 45px;
    }
    .sponsor {
        height: 70px;
    }
    .group-image {
        width: 100%;
        height: auto;
        margin-bottom: 0px;
    }
}

@media all and (min-width: 480px) and (max-width: 768px) {
    /* body, html {
        color: blue;
    } */
    .discounts-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 15px;
    }
    .discount-wrapper {
        width: 100%;
        margin-bottom: 10px;
    }
    .event-edit-container {
        /* width: 100%; */
    }
    .input-column-wrapper {
        display: grid;
        grid-template-columns: 1fr;
    }
    .bars-container {
        display: flex;
        align-items: center;
    }
    .bars-container .mobiletext {
        display: block;
    }
    .bar-wrapper {
        display: none;
    }
    .inline {
        margin-top: 8px;
    }
    h1 {
        font-size: 38px;
        padding: 40px 0 33px 0;
    }
    h2 {
        font-size: 26px;
        padding: 10px 0 20px 0;
    }
    .merch-wrapper {
        grid-template-columns: 1fr 1fr;
        gap: 25px;
    }
    .info-wrapper {
        padding-bottom: 55px;
    }
    .footer-wrapper {
        padding: 30px 0;
    }
    .socials-icon {
        width: 18px;
        height: 18px;
    }
    .copyright {
        font-size: 13px;
    }
    .bg-image-wrapper {
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
        height: 175px;
    }
    .hero > .bg-image-wrapper {
        height: 350px;
    }
    .content-wrapper {
        padding: 0 22px;
    }
    .announcements-wrapper {
        padding: 0px 22px;
        width: 100%;
    }
    .verify-wrapper {
        grid-template-columns: 1fr;
    }
    .bigtext-wrapper {
        padding: 30px 0 20px 0;
    }
    .bigtext-wrapper > h1 {
        font-size: 36px;
    }
    .skis-image-vertical {
        display: none;
    }
    .skis-image-horizontal {
        display: block;
        height: 700px;
        top: -515px;
        left: calc(40vw - 160px);
    }
    .carousel-wrapper {
        height: 60px;
    }
    .sponsor-wrapper {
        padding: 0 40px;
    }
    .sponsor {
        height: 60px;
    }
    .group-image {
        width: 100%;
        height: auto;
        margin-bottom: 0px;
    }
}

@media all and (max-width: 480px) {
    /* body, html {
        color: purple;
    } */
    .event-edit-container.max-width {
        width: 100vw;
    }
    .discounts-container {
        display: grid;
        grid-template-columns: 1fr;
    }
    .discount-wrapper {
        width: 100%;
        margin-bottom: 10px;
    }
    .event-edit-container {
        margin: 0;
        overflow-y: scroll;
        top: 0px;
        left: 0px;
        position: fixed;
        width: 100%;
        height: 100%;
        max-height: none;
    }
    .input-column-wrapper {
        display: grid;
        grid-template-columns: 1fr;
    }
    .bars-container {
        display: flex;
        align-items: center;
    }
    .bars-container .mobiletext {
        display: block;
    }
    .bar-wrapper {
        display: none;
    }
    .inline {
        margin-top: 6px;
    }
    h1 {
        font-size: 30px;
        padding: 35px 0 25px 0;
    }
    h2 {
        font-size: 24px;
        padding: 10px 0 20px 0;
    }
    .merch-wrapper {
        grid-template-columns: 1fr;
    }
    .info-wrapper {
        padding-bottom: 45px;
    }
    .footer-wrapper {
        padding: 25px 0;
    }
    .socials-icon {
        width: 16px;
        height: 16px;
    }
    .copyright {
        font-size: 12px;
    }
    .bg-image-wrapper {
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        height: 150px;
    }
    .hero > .bg-image-wrapper {
        height: 300px;
    }
    .content-wrapper {
        padding: 0 16px;
    }
    .announcements-wrapper {
        padding: 0px 16px;
        width: 100%;
    }
    .verify-wrapper {
        grid-template-columns: 1fr;
    }
    .bigtext-wrapper {
        padding: 25px 0 15px 0;
    }
    .bigtext-wrapper > h1 {
        font-size: 27px;
    }
    .skis-image-vertical {
        display: none;
    }
    .skis-image-horizontal {
        display: block;
        height: 500px;
        top: -390px;
        left: calc(40vw - 160px);
    }
    .carousel-wrapper {
        height: 55px;
    }
    .sponsor-wrapper {
        padding: 0 35px;
    }
    .sponsor {
        height: 55px;
    }
    .group-image {
        width: 100%;
        height: auto;
        margin-bottom: 0px;
    }
}
