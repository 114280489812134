/* ----- Global Styles ------ */

a {
    display: inline-block;
}
body {
    overscroll-behavior-x: none;
    overscroll-behavior-y: none;
}
html,
body {
    color: #333e4a;
    background-color: #fafbff;
    font-family: 'NeueHaas-Roman', Sans-Serif;
    line-height: 1.3;
    font-size: 16px;
}
p {
    max-width: 90ch;
}
p.nobreak {
    display: inline;
}
h1,
h2,
h3 {
    font-family: 'Monument', Sans-Serif;
    line-height: 1.2;
}
h1 {
    font-size: 43px;
    padding: 60px 0 45px 0;
}
h2 {
    font-size: 33px;
    padding: 15px 0 20px 0;
}
h3 {
    /* font-size: 20px; */
    padding: 2px 0 13px 0;
}
h4 {
    font-family: 'NeueHaas-Medium', Sans-Serif;
    padding: 2px 0 7px 0;
}
*,
*::before,
*::after {
    box-sizing: border-box;
}
.downtime {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.full-width {
    width: 100%;
}
.h-center {
    display: flex;
    justify-content: center;
    width: 100%;
}
.relative {
    position: relative;
    width: 100%;
}
.content-wrapper {
    width: 100%;
    max-width: 1180px;
    position: relative;
    padding: 0 30px;
}
.no-padding {
    padding: 0;
}
.no-margin {
    margin: 0 !important;
}
.space-between {
    display: flex;
    justify-content: space-between;
}
.bg-image-wrapper {
    position: relative;
    width: 100%;
    height: 250px;
    background-color: rgb(230, 230, 235);
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    overflow: hidden;
}
.bg-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.tinter {
    position: absolute;
    background: linear-gradient(
        180deg,
        rgba(237, 242, 247, 0.8) 0%,
        rgba(237, 242, 247, 0.3) 100%
    );
    width: 100%;
    height: 100%;
    /* Rivalry update: */
    background: linear-gradient(
        180deg,
        rgba(237, 242, 247, 0.8) 0%,
        rgba(237, 242, 247, 0.1) 100%
    );
}
.dropdown {
    position: relative;
    cursor: pointer;
}
.dropdown-content {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    right: 0;
    position: absolute;
    background-color: #fafbff;
    white-space: nowrap;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
    z-index: 1;
}
.dropdown:hover .dropdown-content {
    display: flex;
}
button,
span {
    font-family: 'NeueHaas-Medium', Sans-Serif;
}
.primary {
    background-color: #5463ab;
    color: white;
    cursor: pointer;
    padding: 8px 12px;
    font-size: 14px;
    display: block;
    margin: 12px 0;
}
.primary:hover {
    background-color: #3d58e0;
}
.disabled {
    opacity: 0.5;
    pointer-events: none;
}
.secondary {
    background-color: white;
    color: #5463ab;
    border: 1.5px solid #5463ab;
    cursor: pointer;
    padding: 6.5px 10.5px;
    font-size: 14px;
    display: block;
    margin: 12px 0;
}
.secondary:hover {
    background-color: #5463ab;
    color: white;
}
.inline {
    background-color: transparent;
    color: #5463ab;
    cursor: pointer;
    padding: 4px 8px;
    font-size: 14px;
    display: block;
    margin-top: 18px;
    border: 1.8px solid #5463ab;
}
.inline:hover {
    background-color: #5463ab;
    color: white;
}
.inline-block {
    display: inline-block;
}
.block {
    display: block;
}
.margin-left-small {
    margin-left: 15px;
}
.margin-left {
    margin-left: 30px;
}
.flex {
    display: flex;
}
.icon {
    padding: 5px;
    width: 100%;
    height: 100%;
}
.icon-wrapper {
    display: flex;
    margin-right: 15px;
}
.page {
    padding-top: 30px;
}
.bold {
    font-family: 'NeueHaas-Medium', Sans-Serif;
}

/* ---- Global Components --- */

/* Footer */

.footer-wrapper {
    background-color: rgb(239, 239, 246);
    width: 100%;
    padding: 50px 0;
}
.footer-contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0.5;
}
.socials-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 25px;
}
.socials-icon {
    width: 22px;
    height: 22px;
}
.copyright {
    font-size: 14px;
}

/* -------- HomePage -------- */

/* Hero */

.hero {
    width: 100%;
}
.hero > .bg-image-wrapper {
    height: 460px;
}
.bigtext-wrapper {
    padding: 50px 0 20px 0;
}
.bigtext-wrapper > h1 {
    font-size: 60px;
    padding: 0;
}
.skis-image-vertical {
    position: absolute;
    height: 800px;
    top: 40px;
    right: 120px;
    /* filter: drop-shadow(0 4px 12px #0b14226e); */
    transform: translateY(-50%);
    z-index: 999;
}
.skis-image-horizontal {
    display: none;
    position: absolute;
    left: 220px;
    /* filter: drop-shadow(0 4px 12px #0b14226e); */
    transform: rotateZ(90deg);
    z-index: 999;
}
.carousel-screen {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgb(255, 255, 255);
    background: linear-gradient(
        90deg,
        #fafbff 0%,
        #fafbffd5 6%,
        #fafbffd5 94%,
        #fafbff 100%
    );
}
.carousel-wrapper {
    position: relative;
    width: 100%;
    height: 80px;
    z-index: 0;
    overflow: hidden;
}
.sponsor-wrapper {
    height: 100%;
    position: relative;
    padding: 0 50px;
}
.sponsor {
    height: 80px;
}

/* NavBar */

.navbar-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
    background-color: transparent;
    box-shadow: 0px 3px 5px #0d0e0e00;
}
.navbar {
    display: flex;
    justify-content: center;
    width: 100%;
    transition: all 0.2s;
}
.navbar.active {
    box-shadow: 0px 3px 5px #0d0e0e08;
    background-color: #fafbff;
    padding: 4px 0;
    z-index: 10000;
}
.navlink-wrapper {
    display: grid;
    grid-template-columns: 0.8fr 1fr 1fr 1fr;
    gap: 10px;
    transition: all 0.2s;
}
.navlink {
    font-family: 'NeueHaas-Medium', Sans-Serif;
    padding: 12px 16px;
    position: relative;
}
.navlink:hover {
    color: #276aef;
}
.logo {
    position: absolute;
    margin: 13px 0 10px 0;
    height: 18px;
}
.open-nav-menu,
.close-nav-menu {
    display: none;

    width: 40px;
    height: 40px;
    margin-top: 0px;
}

/* Announcements */

.announcements-wrapper {
    display: flex;
    padding: 0px 30px;
    font-size: 13px;
    width: 100%;
    height: 35px;
    align-items: center;
    color: rgba(255, 255, 255, 0.904);
    font-family: 'NeueHaas-Roman', Sans-Serif;
    white-space: nowrap;
    overflow: none;
    position: relative;
    background-color: #3056e0;
    /* New adjustments for Rivalry: */
    font-size: 15px;
    font-family: 'NeueHaas-Medium', Sans-Serif;
    background-color: #f0bb4b;
    color: #2d67c3;
    height: 45px;
}
.fade-off {
    position: absolute;
    right: 0;
    width: 70px;
    height: 35px;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(48, 86, 224, 1) 80%
    );
    z-index: 10000;
    /* New adjustments for Rivalry: */
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(109, 25, 25, 0) 80%
    );
    height: 45px;
}
.a-icon {
    margin-right: 10px;
}

/* HomeContents */

.group-image {
    height: 500px;
    width: 700px;
    margin-bottom: 50px;
}
.info-wrapper {
    padding-bottom: 75px;
    position: relative;
}
.info {
    background-color: rgb(239, 239, 246);
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.info p {
    opacity: 0.5;
}

/* -------- Dashboard -------- */

/* DashboardContents */

.user-details {
    position: absolute;
    margin-top: 10px;
    font-size: 12px;
    opacity: 0.7;
}
.product-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    margin: 40px 0 80px 0;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    overflow: hidden;
}
.product-info {
    padding: 12px 16px;
}
.product-title {
    font-family: 'NeueHaas-Medium', Sans-Serif;
}
.product-price {
    opacity: 0.6;
    font-size: 15px;
}
.product-wrapper button {
    margin: 0px;
}

/* Modals */

.modal-wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(3px);
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal {
    z-index: 10010;
}
.puff {
    color: red !important;
}
.modal-header-space-between {
    display: flex;
    justify-content: space-between;
}

.confirm-box {
    padding: 20px;
    background-color: white;
    border: 1px solid #333e4a;
    margin: 20px;
    max-width: 300px;
    min-width: 200px;
}
.confirm-button-grid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    margin-bottom: -10px;
}
.confirm-button-grid.only-confirm {
    grid-template-columns: 1fr;
}

/* VerifyAccount */

.verify-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 30px;
}
.verify-block {
    display: flex;
    flex-direction: column;
    background-color: rgb(239, 239, 246);
    padding: 20px;
}
.verify-header {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}
.verify-header h3 {
    margin-top: 3px;
    padding: 0;
}
.verify-icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}
.verify-desc {
    font-size: 14px;
    margin-bottom: 5px;
}

/* File input */
.file-search-wrapper {
    display: flex;
    align-items: center;
    color: #333e4a;
    border: 1px solid #333e4a;
    cursor: pointer;
    position: relative;
    margin: 8px 0;
    font-size: 14px;
    transition: background-color 0.1s;
}
.file-select-icon {
    position: absolute;
    left: 10px;
    pointer-events: none;
}
.verify-file-input {
    cursor: pointer;
    padding-left: 20px;
    margin-left: 10px;
    width: 100%;
}
.file-upload-button {
    margin: 0;
    display: block;
}
::file-selector-button {
    display: inline;
}

/* Text input */
.text-input-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.text-input-wrapper.padded {
    margin: 3px 0 10px 0;
    width: 100%;
    flex-grow: 2;
}
.text-input-wrapper.border {
    border: 1px solid #aebccb;
}
.text-input-wrapper.short {
    width: 200px;
}
.text-input-wrapper.time {
    width: 95px;
}
.text-input {
    color: inherit;
    border-radius: 0;
    padding: 7px 11px;
    border: 1px solid white;
    font-family: 'NeueHaas-Roman', Sans-Serif;
    width: 100%;
    transition: border 0.1s;
}
textarea.text-input.small {
    height: 40px;
    resize: vertical;
}
textarea.text-input.large {
    height: 80px;
    resize: vertical;
}
.red-border {
    border: 1px solid red;
}
.margin-bottom {
    margin-bottom: 10px;
}
.edit-mode-button {
    height: 100%;
    padding-left: 6px;
    font-size: 14px;
    display: flex;
    align-items: center;
    opacity: 0.5;
    transition: opacity 0.1s;
}
.edit-mode-button:hover {
    opacity: 1;
}

/* MobileMask */
.mobile-mask {
    width: 100%;
    height: 100%;
    position: fixed;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #fafbff;
    z-index: 100000;
    text-align: center;
}

/* IKONPanel */

.padded-notice {
    padding: 0 20px 10px 20px;
    text-align: center;
    max-width: 60%;
}
.lock-icon {
    width: 50px;
    height: 50px;
    opacity: 0.5;
    margin-bottom: 20px;
}
.locked-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.bars-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 0px;
}
.bars-container .mobiletext {
    display: none;
    text-align: center;
    width: 250px;
}
.bar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}
.bar {
    width: 130px;
    height: 5px;
    margin-bottom: 10px;
    background-color: #ee5279;
}
.bar.complete {
    background-color: #64be94;
}
.bar-subtext {
    font-size: 14px;
}
.inline-icon {
    width: 11px;
    height: 11px;
    margin-left: 5px;
}
.inline-icon-medium {
    width: 16px;
    height: 16px;
    margin-left: 30px;
    color: rgb(227, 31, 64);
}
.inline-icon-large {
    position: absolute;
    width: 19px;
    height: 19px;
    margin-left: 3px;
}
.ikon-code-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ikon-code {
    font-size: 40px;
    margin: 5px;
    opacity: 1 !important;
}

/* AdminContents */

.member-count-badge {
    background: linear-gradient(207deg, rgba(255,202,18,1) -20%, rgba(255,247,193,1) 45%, rgba(255,187,56,1) 120%);
    border-radius: 5px;
    width: 200px;
    display: inline-block;
    box-shadow: 0px 5px 8px 0px rgba(230,230,230,1);
    padding: 13px 13px 15px 18px;
    margin-bottom: 15px;
    color: rgb(210, 144, 22);
    font-family: 'NeueHaas-Medium', Sans-Serif;
}
.member-count {
    font-size: 50px;
    margin-bottom: -7px;
}

.highlight-red {
    color: rgb(255, 50, 100);
}
.highlight-green {
    color: rgb(0, 156, 75);
}
.checkbox-wrapper {
    display: flex;
    align-items: center;
    height: 50px;
}
.checkbox-wrapper > p {
    margin-top: 2px;
    margin-left: 5px;
}

/* NonNativePage */

.non-native-popup-wrapper {
    background: #6e9cd7;
    background: linear-gradient(
        185deg,
        rgba(226, 167, 242, 1) 0%,
        rgba(189, 184, 255, 1) 37%,
        rgba(110, 156, 215, 1) 100%
    );

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    color: #20272f;
    font-size: 20px;
}
.instructions-wrapper {
    position: relative;
    width: 100%;
    padding: 60px 16px;
}
.text-wrapper {
    position: relative;
    padding-right: 60px;
}
.fallen-skiier-wrapper {
    padding: 0px;
    display: flex;
    width: 100%;
}
.fallen-skiier {
    width: 100%;
    object-fit: contain;
}
.arrow {
    height: 30px;
    position: absolute;
    right: 20px;
    top: 15px;
    animation: MoveUpDown 1s ease-in-out infinite;
}

@keyframes MoveUpDown {
    0%,
    100% {
        top: 15px;
    }
    50% {
        top: 23px;
    }
}

.scrollable-cell {
    overflow: auto; /* Add a scrollbar when content overflows */
    white-space: nowrap; /* Prevent text from wrapping */
}

/* EventsPanel */

.events-container {
    position: relative;
    width: 100%;
    display: inline-flex;
    overflow-x: scroll;
    scrollbar-width: thin;
}
.event {
    background-color: rgb(239, 239, 246);
    width: 400px;
    margin-right: 20px;
    padding: 25px 30px 20px 30px;
    flex-shrink: 0;
}
.event-status-wrapper {
    padding: 2px 5px 0 5px;
    height: 25px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-5px);
    font-family: 'NeueHaas-Medium', Sans-Serif;
    border-radius: 4px;
}
.event-status-wrapper.open {
    color: rgb(27, 111, 82);
    background-color: rgb(140, 223, 194);
}
.event-status-wrapper.full {
    color: rgb(111, 27, 41);
    background-color: rgb(244, 182, 201);
}
.event-status-wrapper.attending {
    color: rgb(27, 111, 82);
    background-color: rgb(140, 223, 194);
}
.edit-buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: right;
    transform: translateY(-5px);
}
.glance-icon {
    width: 17px;
    height: 17px;
    margin-top: 1px;
    margin-right: 5px;
}
.event-glance-wrapper {
    margin-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    opacity: 0.8;
}
.event-glance-wrapper > div {
    margin-right: 15px;
    display: flex;
    flex-wrap: nowrap;
}
.event-glance-wrapper p {
    white-space: nowrap;
}
.external-doc-icon {
    height: 20px;
    width: 20px;
    margin-right: 5px;
}
.external-doc-wrapper {
    padding: 6px 8px 6px 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
}

.event-desc-long {
    margin-bottom: 15px;
}
.radio-option-wrapper {
    display: flex;
    align-items: flex-start;
    margin: 3px 0;
}
.radio-wrapper {
    margin-bottom: 12px;
}
.radio-button {
    margin-right: 8px;
}

/* EditEventsPanel */

.edit-events-wrapper {
    margin-top: 40px;
}
.edit-events-flex {
    display: flex;
    align-items: center;
}
.edit-icon {
    width: 17px;
    height: 17px;
    margin-left: 5px;
}
.event-header-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
}

/* EditEventModal */

.event-edit-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    /* background-color: rgb(230, 230, 235); */
    background-color: white;
    border: 1px solid #333e4a;
    margin: 20px;
    max-height: 90vh;
    overflow-y: scroll;
}
.event-edit-container.max-width {
    width: 90vw;
}
.checkbox-inline-wrapper {
    display: flex;
    /* justify-content: space-between; */
    margin: 0 0 3px 0;
}
.checkbox-inline-wrapper.margin-bottom {
    display: flex;
    /* justify-content: space-between; */
    margin: 0 0 10px 0;
}
.datetime-inline-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 0 0px 0;
}
.input-column-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 15px;
}

/* CustomQuestionsPanel */

.header-wrapper-space-between {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.indented {
    border-left: 3px solid rgb(220, 220, 220);
    padding-left: 15px;
    margin: 6px 0 10px 0;
}
.select {
    width: 100%;
    padding: 7px 9px;
    font-family: inherit;
    border-radius: 0;
    border: 1px solid #aebccb;
    margin: 3px 0 10px 0;
}
.inline-square {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    color: #5463ab;
    border: 1.8px solid #5463ab;
}

.inline-square:hover {
    background-color: #5463ab;
    color: white;
}

/* MerchPage */

.merch-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 40px;
    margin-bottom: 100px;
}
.item-wrapper {
    display: inline-block;
    overflow: hidden;
}
.merch-thumbnail-wrapper {
    /* background-color: rgb(239, 239, 246); */
    width: auto;
    height: auto;
    position: relative;
    aspect-ratio: 1/ 1;
}
.merch-thumbnail {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: contain;
}
.item-info-wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    margin-bottom: 40px;
}
.name-price-wrapper {
    display: flex;
    gap: 10px;
}
.merch-name {
    font-family: 'NeueHaas-Medium', Sans-Serif;
}
.limited-tag {
    font-family: 'NeueHaas-Medium', Sans-Serif;
    color: white;
    border-radius: 20px;
    font-size: 12px;
    padding: 3px 8px;
    background-color: rgb(218, 52, 74);
}
.merch-price {
    opacity: 0.5;
}
.variants-wrapper {
    display: flex;
    gap: 5px;
}
.variant-color {
    width: 15px;
    height: 15px;
    border-radius: 20px;
}

/* Discounts */

.discount-wrapper {
    width: 250px;
    padding: 20px 15px;
    position: relative;
    overflow: hidden;
    -webkit-mask-position: 0 5px;
    border-radius: 5px;
    -webkit-mask-image: radial-gradient(
        circle at right 50px bottom 5px,
        transparent 5px,
        red 5.5px
    );
    color: white;
    display: inline-block;
    margin-bottom: 20px;
    margin-right: 20px;
}
.discount-wrapper:before {
    display: block;
    width: 250%;
    height: 470%;
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    background: rgb(127, 149, 255);
    background: linear-gradient(
        125deg,
        rgb(127, 212, 255) 0%,
        rgba(133, 72, 255, 1) 37%,
        rgb(255, 83, 232) 100%
    );
    color: white;
    transform: translate(-25%, -25%);
    transition: all 0.3s;
    z-index: -1;
}
.discount-wrapper:hover:before {
    transform: translate(-25%, -25%) rotate(-180deg);
}
.discount-sponsor {
    font-family: 'NeueHaas-Medium', Sans-Serif;
    font-size: 22px;
    z-index: 999;
}
.discount-text {
    font-size: 27px;
    margin: 0 0 5px 0;
    z-index: 999;
}
.discount-code {
    font-family: monospace;
    z-index: 999;
    font-size: 14px;
    display: inline-block;
    padding: 3px 5px;
    border: 1px dashed white;
    border-radius: 3px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
        57deg,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0) 100%
    );
    width: auto;
}
